body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

textarea {
  font-family: inherit;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  /* align-items: center; */
}

div:not(div[class*="react-select"], .grid, .form-check, .text-truncate),
button,
form,
i {
  /* align-self: stretch; */
  display: flex !important;
  box-sizing: border-box;
  flex-direction: column;
}

html {
  --gray-color: #1d1d1b;
  --accent-color: #e30613;
  --black-color: #121212;
  --white-color: #f5f5f5;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--bs-gray-700) inset !important;
  box-shadow: 0 0 0 30px var(--bs-gray-700) inset !important;
}

/*Change text in autofill textbox*/
input:-webkit-autofill {
  -webkit-text-fill-color: var(--bs-gray-100) !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin-left: 2px;
}

/* Hide input number spinner */
/* Chrome, Safari, Edge, Opera */
/* input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
} */

/* Firefox */
/* input[type=number] {
  -moz-appearance: textfield;
} */

.grid {
  display: grid !important;
}

.t-02 {
  transition: all 0.2s;
}

.t-05 {
  transition: all 0.5s;
}
