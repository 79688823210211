$primary: #e30613;
$secondary: #1d1d1b;
$white: #f5f5f5;

$white: #fafafa !default;
$gray-100: #e1e1e1 !default; //~~
$gray-200: #cfcfcf !default; //~~
$gray-300: #b1b1b1 !default; //~~
$gray-400: #9e9e9e !default; //~~
$gray-500: #7e7e7e !default; //~~
$gray-600: #626262 !default; //~~
$gray-700: #515151 !default; //~~
$gray-800: #3b3b3b !default; //~~
$gray-900: #222 !default; //~~
$black: #111 !default; //~~

$blue: #375a7f !default;
$indigo: #673ab7 !default; //~~
$purple: #654ea3 !default; // Panatone CotY 2018
$pink: #e83e8c !default;
$red: #e74c3c !default; //**
$orange: #fd7e14 !default;
$yellow: #f39c12 !default; //**
$green: #00bc8c !default; //**
$teal: #45b5aa !default; // Panatone CotY 2010
$cyan: #17a2b8 !default;

$colors: (
  "blue": $blue,
  "indigo": $indigo,
  "purple": $purple,
  "pink": $pink,
  "red": $red,
  "orange": $orange,
  "yellow": $yellow,
  "green": $green,
  "teal": $teal,
  "cyan": $cyan,
  "white": $white,
  "black": $black,
  "gray": #7e7e7e,
  // $gray-600,
  "gray-dark": #121212 //$gray-800,,
) !default;

$primary: $blue !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-400 !default;
$dark: $gray-800 !default;

$min-contrast-ratio: 4.5 !default;

$gradient: linear-gradient(
  180deg,
  rgba($black, 0.15),
  rgba($black, 0)
) !default;

$body-bg: $gray-900 !default; // $white !default;
$body-color: $gray-100 !default; // $gray-900 !default;

$link-color: shift-color($primary, -15%) !default;
$link-shade-percentage: 20% !default; // TODO: should be -20%;
$link-hover-color: tint-color(
  $link-color,
  $link-shade-percentage
) !default; // TODO: shift-color

$border-color: $gray-700 !default;

$component-active-bg: $primary !default;

$text-muted: $gray-400 !default;

$hr-color: $white !default;
$hr-bg-color: currentColor !default;
$hr-opacity: 0.1 !default;

$table-striped-bg-factor: 0.05 !default;
$table-striped-bg: rgba($white, $table-striped-bg-factor) !default;

$table-active-bg-factor: 0.1 !default;
$table-active-bg: rgba($white, $table-active-bg-factor) !default;

$table-hover-bg-factor: 0.075 !default;
$table-hover-bg: rgba($white, $table-hover-bg-factor) !default;

$table-bg-scale: 50% !default;

$input-disabled-bg: $gray-900 !default;

$input-color: $gray-300 !default;
$input-border-color: $gray-700 !default;

$input-placeholder-color: mix($gray-600, $gray-700, 50%) !default;

$form-check-input-active-filter: brightness(90%) !default; // TODO: test

$form-check-input-border: 1px solid rgba($white, 0.25) !default;

$form-switch-color: rgba($white, 0.25) !default;

$input-group-addon-bg: $gray-800 !default;

$form-select-disabled-color: null !default;

$form-select-disabled-bg: $gray-800 !default;

$form-select-indicator-color: $gray-200 !default;

$form-range-track-bg: $gray-700 !default;

$nav-link-disabled-color: $gray-400 !default;

$nav-tabs-border-color: $gray-700 !default;
$nav-tabs-link-hover-border-color: $gray-800 $gray-800 $nav-tabs-border-color !default;
$nav-tabs-link-active-color: $gray-300 !default;

$nav-tabs-link-active-bg: $body-bg !default;
$nav-tabs-link-active-border-color: $gray-700 $gray-700 $nav-tabs-link-active-bg !default;

$navbar-light-color: rgba($white, 0.55) !default;
$navbar-light-hover-color: rgba($white, 0.7) !default;
$navbar-light-active-color: rgba($white, 0.9) !default;
$navbar-light-disabled-color: rgba($white, 0.3) !default;

$navbar-light-toggler-border-color: rgba($white, 0.1) !default;

$dropdown-bg: $black !default;
$dropdown-border-color: rgba($white, 0.15) !default;

$dropdown-link-color: $gray-100 !default;

$dropdown-link-hover-bg: $gray-800 !default;

$dropdown-header-color: $gray-400 !default;

$dropdown-dark-color: $gray-700 !default;
$dropdown-dark-bg: $gray-200 !default;

$dropdown-dark-link-hover-color: $black !default;
$dropdown-dark-link-hover-bg: rgba($black, 0.15) !default;

$pagination-bg: $gray-800 !default;
$pagination-border-color: $gray-700 !default;

$pagination-focus-bg: $gray-800 !default;

$pagination-focus-outline: 0 !default; // ???

$pagination-hover-bg: $gray-700 !default;
$pagination-hover-border-color: mix($gray-700, $gray-700, 50%) !default;

$pagination-disabled-color: mix($gray-600, $gray-700, 50%) !default;
$pagination-disabled-bg: $gray-900 !default;
$pagination-disabled-border-color: $gray-700 !default;

$placeholder-opacity-max: 0.5 !default;
$placeholder-opacity-min: 0.2 !default;

$card-border-color: rgba($white, 0.125) !default;
$card-cap-bg: rgba($white, 0.03) !default;
$card-cap-color: null !default;
$card-color: null !default;
$card-box-shadow: null !default;
$card-bg: $gray-900 !default;

$accordion-bg: $body-bg !default;
$accordion-border-color: rgba($white, 0.125) !default;

$accordion-button-bg: $accordion-bg !default;
$accordion-button-active-bg: shade-color($component-active-bg, 50%) !default;
$accordion-button-active-color: tint-color($primary, 40%) !default;

$tooltip-color: $black !default;
$tooltip-bg: $white !default;

$popover-bg: $black !default;
$popover-border-color: rgba($white, 0.2) !default;

$popover-header-bg: tint-color($popover-bg, 6%) !default;

$toast-background-color: rgba($black, 0.85) !default;
$toast-border-color: rgba($white, 0.1) !default;

$toast-header-color: $gray-400 !default;
$toast-header-background-color: rgba($black, 0.85) !default;
$toast-header-border-color: rgba($white, 0.05) !default;

$modal-content-bg: mix($gray-800, $gray-900, 50%) !default;
$modal-content-border-color: rgba($white, 0.2) !default;

$modal-backdrop-opacity: 0.85 !default;

$alert-bg-scale: 50% !default;
$alert-border-scale: 40% !default;
$alert-color-scale: -30% !default;

$progress-bg: $gray-800 !default;

$progress-bar-color: $black !default;

$list-group-color: $gray-100 !default;
$list-group-bg: $gray-900 !default; // $white !default;
$list-group-border-color: rgba($white, 0.125) !default;

$list-group-item-bg-scale: 50% !default;
$list-group-item-color-scale: -30% !default;

$list-group-hover-bg: mix($gray-800, $gray-900, 50%) !default;

$list-group-disabled-color: $gray-400 !default;

$list-group-action-color: $gray-300 !default;

$list-group-action-active-bg: $gray-800 !default;

$breadcrumb-divider-color: $gray-400 !default;
$breadcrumb-active-color: $gray-400 !default;

$carousel-indicator-active-bg: $black !default;

$carousel-caption-color: $black !default;

$carousel-dark-indicator-active-bg: $white !default;
$carousel-dark-caption-color: $white !default;

$btn-close-color: $white !default;

$code-color: $teal !default;

$kbd-color: $gray-100 !default;
$kbd-bg: $gray-800 !default;

$pre-color: $gray-100 !default;

$selection-color: $gray-200 !default;
$selection-bg: rgba($cyan, 0.5) !default;
