.s-28px{
  width: 28px !important;
  height: 28px !important;
}

.s-38px{
  width: 38px !important;
  height: 38px !important;
}

.s-76px{
  width: 76px !important;
  height: 76px !important;
}

.w-120px{
  width: 120px !important;
}

.ph-38px{
  padding-left: 38px !important;
  padding-right: 38px !important;
}


.flex-center {
  justify-content: center;
  align-items: center;
}

.m3-row, .m2-row, .m1-row {
  flex-direction: row !important;
}

.m3-row > *:not(:first-child) {
  margin-left: 16px;
}

.m3-col > *:not(:first-child) {
  margin-top: 16px;
}

.m2-row > *:not(:first-child) {
  margin-left: 8px;
}

.m2-col > *:not(:first-child) {
  margin-top: 8px;
}

.m1-row > *:not(:first-child) {
  margin-left: 4px;
}

.m1-col > *:not(:first-child) {
  margin-top: 4px;
}