$primary: #e30613;
$secondary: #1d1d1b;
$white: #f5f5f5;

// dark mode
@import "custom-dark";

@import "bootstrap";

.input-group,
.modal-header,
.modal-footer,
.btn-group,
.row {
  flex-direction: row !important;
}

.tooltip {
  left: -9999px;
}

.btn-outline-primary {
  color: var(--bs-gray-300);
}

.invalid-feedback {
  color: var(--bs-orange);
  margin-top: 0;
}

.form-select.no-validate:valid,
.form-select.no-validate:invalid {
  padding: $input-padding-y $input-padding-x !important;
  background-repeat: no-repeat !important;
  background-image: escape-svg($form-select-indicator) !important;
  background-position: $form-select-bg-position !important;
  background-size: $form-select-bg-size !important;
  border: $form-select-border-width solid $form-select-border-color;
}

.form-select.no-validate:valid:focus {
  border-color: $form-select-focus-border-color;
  outline: 0;
  @if $enable-shadows {
    @include box-shadow($form-select-box-shadow, $form-select-focus-box-shadow);
  } @else {
    // Avoid using mixin so we can pass custom focus shadow properly
    box-shadow: $form-select-focus-box-shadow;
  }
}

[disabled] {
  pointer-events: none;
  opacity: $btn-disabled-opacity;
  @include box-shadow(none);
}

.react-select__control {
  flex-direction: row !important;
  background-color: $form-select-bg !important;
  border: $form-select-border-width solid $form-select-border-color !important;
  @include border-radius($form-select-border-radius, 0);
  @include box-shadow($form-select-box-shadow);
  @include transition($form-select-transition);
}

.react-select__control--is-disabled {
  color: $form-select-disabled-color !important;
  background-color: $form-select-disabled-bg !important;
  border-color: $form-select-disabled-border-color !important;
}

.react-select__menu {
  background-color: $form-select-bg !important;
  border: 0.5px solid #858585 !important;
  border-radius: 0 !important;
  margin: 0 !important;
}
.react-select__menu-list {
  padding: 0 !important;
}

.react-select__single-value,
.react-select__option {
  color: $form-select-color !important;
  text-overflow: unset !important;
}

.react-select__option--is-selected {
  background-color: unset !important;
}

.react-select__option--is-focused {
  background-color: #99c8ff !important;
  color: #3b3b3b !important;
}

.react-select__option {
  padding: 0px 12px !important;
  margin-top: -1px !important;
  line-height: 23px;
  overflow: hidden;
}

.react-select__control--is-focused {
  border-color: $form-select-focus-border-color !important;
  outline: 0 !important;
  @if $enable-shadows {
    @include box-shadow($form-select-box-shadow, $form-select-focus-box-shadow);
  } @else {
    // Avoid using mixin so we can pass custom focus shadow properly
    box-shadow: $form-select-focus-box-shadow !important;
  }
}

.react-select__placeholder,
.react-select__input {
  color: $form-select-color !important;
}

.react-select__value-container {
  padding: 2px 0px 2px 10px !important;
}

.react-select__multi-value {
  background-color: $primary !important;
}

.react-select__multi-value__label {
  color: $white !important;
}

$background-select: $primary;
$border-select: $primary;
$color-select: color-contrast($primary);

$hover-background-select: if(
  $color-select == $color-contrast-light,
  shade-color($background-select, $btn-hover-bg-shade-amount),
  tint-color($background-select, $btn-hover-bg-tint-amount)
);

$hover-border-select: if(
  $color-select == $color-contrast-light,
  shade-color($border-select, $btn-hover-border-shade-amount),
  tint-color($border-select, $btn-hover-border-tint-amount)
);

$hover-color-select: color-contrast($hover-background-select);

@mixin gradient-bg-important($color: null) {
  background-color: $color !important;

  @if $enable-gradients {
    background-image: var(--#{$variable-prefix}gradient);
  }
}

.react-select__multi-value__remove {
  color: $color-select !important;
  @include gradient-bg-important($background-select);
  border-color: $border-select;
  @include box-shadow($btn-box-shadow);
}

.react-select__multi-value__remove:hover {
  color: $hover-color-select !important;
  @include gradient-bg-important($hover-background-select);
  border-color: $hover-border-select !important;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.react-select__dropdown-indicator {
  background-repeat: no-repeat !important;
  background-image: escape-svg($form-select-indicator) !important;
  background-position: $form-select-bg-position !important;
  background-size: $form-select-bg-size !important;
}

.react-select__indicator > svg,
.react-select__multi-value__remove > svg {
  color: #ffffff00 !important;
}

.react-select__indicator-separator {
  display: none !important;
  // background-color: #cfcfcf !important;
  // border-radius: 2px !important;
  // width: 1.5px !important;
  // margin: 14px 0px !important;
}

.react-select__clear-indicator {
  position: relative;
  color: #cfcfcf !important;
}

.react-select__clear-indicator::before {
  font-family: bootstrap-icons !important;
  content: "\f62A";
  font-size: 24px;
  top: 0px;
  right: 6px;
  position: absolute;
}

.react-select__multi-value__remove {
  position: relative;
}

.react-select__multi-value__remove::before {
  font-family: bootstrap-icons !important;
  content: "\f62A";
  right: 3px;
  position: absolute;
}

.react-select__input-container {
  padding-bottom: 0px !important;
}

// Scroll bar
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: tint-color($body-bg, 10%);
  border-radius: 12px;
}

::-webkit-scrollbar-thumb:hover {
  background: tint-color($body-bg, 20%);
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}
